import { VFC, useState } from "react";
import ReactTooltip from "react-tooltip";
import { AcademyShareModal } from "./AcademyShareModal";
import { WatchLinkTooltipContent } from "../common/watch_link/WatchLinkTooltipContent";
import { AcademyWatchLinkType } from "../../types/academyWatchLink";

export interface Props {
  csrfToken: string;
  academyId: number;
  shareUrl: string;
  watchLinkType: AcademyWatchLinkType;
  inviteMembersCount?: number;
  domainWatchLinkAvailable: boolean;
}

export const AcademyWatchLinkIconMap: Record<AcademyWatchLinkType, string> = {
  team_only: "lock",
  member_only: "people",
  domain_only: "domain",
  no_limit: "language",
};

export const AcademyShareMenu: VFC<Props> = (props) => {
  const [watchLinkType, setWatchLinkType] = useState(props.watchLinkType);
  const [inviteMembersCount, setInviteMembersCount] = useState(
    props.inviteMembersCount
  );
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      <button
        className="c-button--primary"
        style={{ minWidth: "21rem" }}
        onClick={() => setModalVisible(true)}
        data-tip=""
        data-for="academy-share-tooltip"
      >
        <span className="material-icons-round c-button__icon">
          {AcademyWatchLinkIconMap[watchLinkType]}
        </span>
        共有リンクを設定
      </button>

      {/* eslint-disable-next-line react/forbid-elements */}
      <ReactTooltip
        id="academy-share-tooltip"
        effect="solid"
        place="bottom"
        globalEventOff="click"
      >
        <AcademyWatchLinkTooltipContent
          linkIcon={AcademyWatchLinkIconMap[watchLinkType]}
          type={watchLinkType}
          inviteMembersCount={inviteMembersCount}
        />
      </ReactTooltip>

      <AcademyShareModal
        isOpen={modalVisible}
        requestClose={() => setModalVisible(false)}
        onUpdated={(type, count) => {
          setWatchLinkType(type);
          setInviteMembersCount(count);
        }}
        type={watchLinkType}
        csrfToken={props.csrfToken}
        academyId={props.academyId}
        shareUrl={props.shareUrl}
        domainWatchLinkAvailable={props.domainWatchLinkAvailable}
      />
    </>
  );
};

export default AcademyShareMenu;

const AcademyWatchLinkTooltipContent: VFC<{
  linkIcon: string;
  type: AcademyWatchLinkType;
  inviteMembersCount: number;
}> = (props) => {
  let tooltipTitle: string;
  let tooltipDesc: string;
  switch (props.type) {
    case "team_only":
      tooltipTitle = "非公開";
      break;
    case "member_only":
      tooltipTitle = "特定の人";
      tooltipDesc = `指定された${
        props.inviteMembersCount || 0
      }人に共有されています`;
      break;
    case "domain_only":
      tooltipTitle = "特定のドメイン";
      tooltipDesc = "指定されたドメインのユーザーのみが閲覧できます";
      break;
    case "no_limit":
      tooltipTitle = "一般公開";
      tooltipDesc =
        "リンクを知っている人は、誰でもビデオを閲覧することができます";
      break;
    default:
      break;
  }

  return (
    <WatchLinkTooltipContent
      icon={props.linkIcon}
      title={tooltipTitle}
      description={tooltipDesc}
    />
  );
};
