// videojsの共通処理や型定義をまとめるためのwrapper

import { default as videojsOriginal } from "video.js";
import "videojs-hls-quality-selector";

// 副作用で暗黙にaddLanguageを呼び出すのは動作しないので、明示的に呼び出す
import langJa from "video.js/dist/lang/ja.json";
videojsOriginal.addLanguage("ja", langJa);

export const videojs: VideoJs = videojsOriginal;

interface VideoJs {
  (...args: Parameters<typeof videojsOriginal>): VideoJsPlayer;
  // 必要に応じてvideojsのモジュール定義を追加する
  // 例: browser: typeof videojsOriginal.browser;
}

// videojsの足りない型定義を補完するworkaround
interface VideoJsPlayer extends ReturnType<typeof videojsOriginal> {
  /** https://docs.videojs.com/texttracksettings */
  textTrackSettings?: import("video.js/dist/types/tracks/text-track-settings").default;
  textTracks?: () => TextTrackList;
  textTrackDisplay?: {
    updateDisplay(): void;
  };
  /** https://github.com/chrisboustead/videojs-hls-quality-selector */
  hlsQualitySelector?: () => void;
  /** https://github.com/videojs/videojs-contrib-quality-levels */
  qualityLevels?: () => QualityLevelList;
  /** https://github.com/videojs/http-streaming/blob/v3.13.2/docs/reload-source-on-error.md */
  reloadSourceOnError?: (options?: {
    errorInterval?: number;
    getSource?: (
      reload: (src: Parameters<VideoJsPlayer["src"]>[0]) => void
    ) => void;
  }) => void;
}

// videojs-contrib-quality-levels の型定義を補完するworkaround
// パッケージに含まれている型定義が実態と差があるため再定義する。
type _QualityLevelList =
  import("videojs-contrib-quality-levels/dist/types/quality-level-list").default;
type _QualityLevel =
  import("videojs-contrib-quality-levels/dist/types/quality-level").default;
interface QualityLevelList extends _QualityLevelList {
  get selectedIndex(): number;
  get length(): number;
  [Symbol.iterator]: () => IterableIterator<QualityLevel>;
}
interface QualityLevel extends _QualityLevel {
  get enabled(): boolean;
  set enabled(value: boolean);
}
