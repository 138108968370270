import { VFC, FC, useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import type { VideoWatchLinkType } from "../../types/videoWatchLink";
import { VideoShareModal } from "./VideoShareModal";
import { WatchLinkTooltipContent } from "../common/watch_link/WatchLinkTooltipContent";

interface Props {
  csrfToken: string;
  videoId: number;
  videoPlayable: boolean;
  videoWatchLinkType: VideoWatchLinkType;
  inviteMembersCount?: number;
  shareUrl: string;
  embedHtml: string;
  domainWatchLinkAvailable: boolean;
  watchLinkConditionAvailable?: boolean;
}

const CopyButton: FC<{
  className: string;
  iconName: string;
  value: string;
  disabled: boolean;
  children: string;
}> = (props) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setTimeout(() => setCopied(false), 3000);
  }, [copied]);

  return (
    <button
      className={`p-video-share-menu__copy-button ${props.className}`}
      disabled={props.disabled}
      onClick={async () => {
        await navigator.clipboard.writeText(props.value);
        setCopied(true);
      }}
    >
      <span className={!copied ? "is-show" : ""}>
        <span className="material-icons-round icon">{props.iconName}</span>
        {props.children}
      </span>
      <span className={copied ? "is-show" : ""}>
        <span className="material-icons-round icon bold">done</span>
        コピーしました
      </span>
    </button>
  );
};

export const linkIconMap: Record<VideoWatchLinkType, string> = {
  video_create_user_only: "lock",
  member_only: "people",
  domain_only: "domain",
  no_limit: "language",
};

export const VideoShareMenu: VFC<Props> = (props) => {
  const [watchLinkType, setWatchLinkType] = useState(props.videoWatchLinkType);
  const [inviteMembersCount, setInviteMembersCount] = useState(
    props.inviteMembersCount
  );
  const [modalVisible, setModalVisible] = useState(false);

  const linkIcon = linkIconMap[watchLinkType];

  return (
    <div className="p-video-share-menu__container">
      <p className="p-video-share-menu__title">ビデオの共有</p>
      <div
        data-multiline={true}
        data-tip={
          props.videoPlayable
            ? undefined
            : "エンコード中です。<br />少し待ってリロードしてください。"
        }
      >
        <button
          className="p-video-share-menu__copy-button primary"
          disabled={!props.videoPlayable}
          onClick={() => setModalVisible(true)}
          data-tip=""
          data-for="video-share-tooltip"
        >
          <span className="is-show">
            <span className="material-icons-round icon">{linkIcon}</span>
            共有リンクを設定
          </span>
        </button>
      </div>

      <div
        data-multiline={true}
        data-tip={
          props.videoPlayable
            ? undefined
            : "エンコード中です。<br />少し待ってリロードしてください。"
        }
      >
        <CopyButton
          className="secondary p-video-share-menu__spacer"
          iconName="code"
          value={props.embedHtml}
          disabled={!props.videoPlayable}
        >
          ビデオの埋め込み
        </CopyButton>
      </div>

      {/* eslint-disable-next-line react/forbid-elements */}
      <ReactTooltip
        id="video-share-tooltip"
        effect="solid"
        place="bottom"
        globalEventOff="click"
      >
        <VideoWatchLinkTooltipContent
          type={watchLinkType}
          inviteMembersCount={inviteMembersCount}
        />
      </ReactTooltip>
      <VideoShareModal
        isOpen={modalVisible}
        requestClose={() => setModalVisible(false)}
        onUpdated={(type, count) => {
          setWatchLinkType(type);
          setInviteMembersCount(count);
        }}
        type={watchLinkType}
        csrfToken={props.csrfToken}
        videoId={props.videoId}
        shareUrl={props.shareUrl}
        domainWatchLinkAvailable={props.domainWatchLinkAvailable}
        watchLinkConditionAvailable={props.watchLinkConditionAvailable}
      />
    </div>
  );
};

export default VideoShareMenu;

export const VideoWatchLinkTooltipContent: VFC<{
  type: VideoWatchLinkType;
  inviteMembersCount: number;
}> = (props) => {
  const linkIcon = linkIconMap[props.type];
  let tooltipTitle: string;
  let tooltipDesc: string;
  switch (props.type) {
    case "video_create_user_only":
      tooltipTitle = "非公開";
      break;
    case "member_only":
      tooltipTitle = "特定の人";
      tooltipDesc = `指定された${
        props.inviteMembersCount || 0
      }人に共有されています`;
      break;
    case "domain_only":
      tooltipTitle = "特定のドメイン";
      tooltipDesc = "指定されたドメインのユーザーのみが閲覧できます";
      break;
    case "no_limit":
      tooltipTitle = "一般公開";
      tooltipDesc =
        "リンクを知っている人は、誰でもビデオを閲覧することができます";
      break;
    default:
      break;
  }

  return (
    <WatchLinkTooltipContent
      icon={linkIcon}
      title={tooltipTitle}
      description={tooltipDesc}
    />
  );
};
