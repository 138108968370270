import {
  startTransition,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import type { VideoWatchLinkType } from "../../types/videoWatchLink";
import { WordPronounciationsContext } from "./WordPronounciationsContext";
import {
  getDesignScenes,
  getVideo,
  getVideoWatchLink,
  uploadDesignSceneAudio,
} from "./requests";
import { DesignScene, DesignSceneWithAudio } from "./helpers";
import { useDebounceState } from "../../lib/useDebounce";
import { AzureCognitiveService } from "../../lib/AzureCognitiveService";

export function useInitialDesignScenes(designId: number) {
  const [scenes, setScenes] = useState<DesignScene[]>();
  const initialReqRef = useRef(false);

  if (!initialReqRef.current) {
    initialReqRef.current = true;
    getDesignScenes(designId).then((scenes) => {
      setScenes(scenes);
    });
  }

  return [scenes, setScenes] as const;
}

export function useInitialVideo(videoId: number) {
  const [video, setVideo] = useState<Awaited<ReturnType<typeof getVideo>>>();
  const initialReqRef = useRef(false);

  if (!initialReqRef.current) {
    initialReqRef.current = true;
    getVideo(videoId).then((video) => {
      setVideo(video);
    });
  }

  return [video, setVideo] as const;
}

export function useVideoWatchLinkTooltipValue(videoId: number) {
  const [value, setValue] = useState<{
    type: VideoWatchLinkType;
    inviteMembersCount: number;
  }>({ type: null, inviteMembersCount: null });
  const initialReqRef = useRef(false);

  if (!initialReqRef.current) {
    initialReqRef.current = true;
    getVideoWatchLink(videoId).then((watchLink) => {
      setValue({
        type: watchLink.typeName as VideoWatchLinkType,
        inviteMembersCount: watchLink.members?.length,
      });
    });
  }

  return [value, setValue] as const;
}

export function useDesignScenesWithAudio(
  csrfToken: string,
  scenes: DesignScene[]
) {
  const [debouncedScenes] = useDebounceState(scenes, 500);
  const [isGeneratingAudio, setIsGeneratingAudio] = useState(false);
  const [designSceneWithAudio, setDesignSceneWithAudio] =
    useState<DesignSceneWithAudio[]>();
  const wordPronounciationsPromise = useContext(WordPronounciationsContext);

  const audioService = useRef<AzureCognitiveService>();
  const caches =
    useRef<Map<string, { audioUrl: string; durationMs: number }>>();

  useEffect(() => {
    if (!caches.current) {
      caches.current = new Map();
    }
    if (!audioService.current) {
      audioService.current = new AzureCognitiveService(csrfToken);
      audioService.current.ensureToken();
    }
    return () => {
      caches.current?.clear();
      audioService.current?.disposeAudioCaches();
      audioService.current = null;
    };
  }, [csrfToken]);

  useEffect(() => {
    if (!debouncedScenes) {
      return;
    }
    setIsGeneratingAudio(debouncedScenes.some((scene) => !!scene.textHtml));

    // フラグによる排他制御と、AbortSignalによるHTTPリクエストのキャンセルによって、古い処理の破棄を行う。
    let ignore = false;
    const abortController = new AbortController();

    Promise.all(
      debouncedScenes.map((scene) =>
        (async () => {
          const wordPronounciations = await wordPronounciationsPromise;
          // AudioServiceが透過的キャッシュを備えて十分に高速に動作する前提
          const audioInfo =
            await audioService.current.convertWithWordPronounciations(
              {
                text: scene.textHtml,
                voiceName: scene.audioData.voiceName,
                speechRate: scene.audioData.speechRate,
                wordPronounciations,
              },
              { abortSignal: abortController.signal }
            );

          // 句読点だけなど有意でないテキストの場合は音声生成結果が空になるので、未入力と同じ扱いにする。
          if (audioInfo.blob.size === 0) {
            return { ...scene, audioUrl: null, durationMs: 0 };
          }

          if (caches.current.has(audioInfo.blobUrl)) {
            const cached = caches.current.get(audioInfo.blobUrl);
            return {
              ...scene,
              audioUrl: cached.audioUrl,
              durationMs: cached.durationMs,
            };
          }

          const file = new File([audioInfo.blob], "temp.mp3", {
            type: audioInfo.blob.type,
          });
          const res = await uploadDesignSceneAudio(csrfToken, scene.id, file, {
            abortSignal: abortController.signal,
          });
          const result: DesignSceneWithAudio = {
            ...scene,
            audioUrl: res.audioUrl,
            durationMs: audioInfo.durationMs,
          };
          caches.current.set(audioInfo.blobUrl, {
            audioUrl: res.audioUrl,
            durationMs: audioInfo.durationMs,
          });
          return result;
        })()
      )
    )
      .then((scenesWIthAudio) => {
        if (ignore) {
          return;
        }
        setIsGeneratingAudio(false);
        setDesignSceneWithAudio(scenesWIthAudio);
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.debug(error);
          return; // AbortSignalによるキャンセルなので無視
        } else {
          console.error(error);
        }
        throw error; // エラーを上位処理に任せる
      });

    return () => {
      ignore = true;
      abortController.abort();
    };
  }, [csrfToken, debouncedScenes, wordPronounciationsPromise]);

  return [designSceneWithAudio, isGeneratingAudio] as const;
}

export function useExpandable(
  defaultExpanded: boolean,
  thresholdWidth: number
) {
  const width = useWindowWidth();
  const canExpand = useMemo(
    () => width >= thresholdWidth,
    [thresholdWidth, width]
  );
  const [expanded, setExpanded] = useState(defaultExpanded);
  const actualExpanded = useMemo(
    () => canExpand && expanded,
    [canExpand, expanded]
  );

  const toggleExpanded = useCallback(() => {
    setExpanded((prev) => !prev);
  }, []);

  return { expanded: actualExpanded, canExpand, toggleExpanded };
}

function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const onResize = () => {
      startTransition(() => {
        setWidth(window.innerWidth);
      });
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);
  return width;
}
