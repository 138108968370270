/* tslint:disable */
/* This is generated by ts_routes-rails */

export type ScalarType = string | number | boolean;

function $buildOptions(options: any, names: string[]): string {
  if (options) {
    let anchor = "";
    const q = [] as string[];
    for (const key of Object.keys(options)) {
      if (names.indexOf(key) !== -1) {
        // the key is already consumed
        continue;
      }

      const value = options[key];

      if (key === "anchor") {
        anchor = `#${$encode(value)}`;
      } else {
        $buildQuery(q, key, value);
      }
    }
    return (q.length > 0 ? "?" + q.join("&") : "") + anchor;
  } else {
    return "";
  }
}

function $buildQuery(q: string[], key: string, value: any) {
  if ($isScalarType(value)) {
    q.push(`${$encode(key)}=${$encode(value)}`);
  } else if (Array.isArray(value)) {
    for (const v of value) {
      $buildQuery(q, `${key}[]`, v);
    }
  } else if (value != null) {
    // i.e. non-null, non-scalar, non-array type
    for (const k of Object.keys(value)) {
      $buildQuery(q, `${key}[${k}]`, value[k]);
    }
  }
}

function $encode(value: any): string {
  return encodeURIComponent(value);
}

function $isScalarType(value: any): value is ScalarType {
  return (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean"
  );
}

function $isPresent(value: any): boolean {
  return value != null && ("" + value).length > 0;
}

function $hasPresentOwnProperty(options: any, key: string): boolean {
  return options && options.hasOwnProperty(key) && $isPresent(options[key]);
}

/** / */
export function rootPath(options?: object): string {
  return "/" + $buildOptions(options, []);
}

/** /home(.:format) */
export function homePath(options?: object): string {
  return "/" + "home" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /terms(.:format) */
export function termsPath(options?: object): string {
  return "/" + "terms" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /install(.:format) */
export function installPath(options?: object): string {
  return "/" + "install" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /auth/login(.:format) */
export function loginAuthPath(options?: object): string {
  return "/" + "auth" + "/" + "login" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /auth/status(.:format) */
export function statusAuthPath(options?: object): string {
  return "/" + "auth" + "/" + "status" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /auth/logout(.:format) */
export function logoutAuthPath(options?: object): string {
  return "/" + "auth" + "/" + "logout" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /auth/auth0(.:format) */
export function auth0AuthPath(options?: object): string {
  return "/" + "auth" + "/" + "auth0" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /auth/email_verifying(.:format) */
export function emailVerifyingAuthPath(options?: object): string {
  return "/" + "auth" + "/" + "email_verifying" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /auth/auth0/callback(.:format) */
export function callbackAuthAuth0Path(options?: object): string {
  return "/" + "auth" + "/" + "auth0" + "/" + "callback" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /folders/:id(.:format) */
export function folderPath(id: ScalarType, options?: object): string {
  return "/" + "folders" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /video/:id/embed(.:format) */
export function embedVideoWatchPath(id: ScalarType, options?: object): string {
  return "/" + "video" + "/" + id + "/" + "embed" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /video/:id(.:format) */
export function videoWatchPath(id: ScalarType, options?: object): string {
  return "/" + "video" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /design/:id/info(.:format) */
export function infoDesignPath(id: ScalarType, options?: object): string {
  return "/" + "design" + "/" + id + "/" + "info" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /design(.:format) */
export function designIndexPath(options?: object): string {
  return "/" + "design" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /design/:id/edit(.:format) */
export function editDesignPath(id: ScalarType, options?: object): string {
  return "/" + "design" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /video/:video_id/reviews(.:format) */
export function videoReviewsPath(video_id: ScalarType, options?: object): string {
  return "/" + "video" + "/" + video_id + "/" + "reviews" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["video_id","format"]);
}

/** /video/:video_id/subtitles(.:format) */
export function videoSubtitlesPath(video_id: ScalarType, options?: object): string {
  return "/" + "video" + "/" + video_id + "/" + "subtitles" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["video_id","format"]);
}

/** /video/:video_id/timeline/edit(.:format) */
export function editVideoTimelinePath(video_id: ScalarType, options?: object): string {
  return "/" + "video" + "/" + video_id + "/" + "timeline" + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["video_id","format"]);
}

/** /video/:video_id/narrations/edit(.:format) */
export function editVideoNarrationsPath(video_id: ScalarType, options?: object): string {
  return "/" + "video" + "/" + video_id + "/" + "narrations" + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["video_id","format"]);
}

/** /video/:video_id/exam/edit(.:format) */
export function editVideoExamPath(video_id: ScalarType, options?: object): string {
  return "/" + "video" + "/" + video_id + "/" + "exam" + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["video_id","format"]);
}

/** /video/:id(.:format) */
export function videoPath(id: ScalarType, options?: object): string {
  return "/" + "video" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /teams/members/:id(.:format) */
export function teamsMemberPath(id: ScalarType, options?: object): string {
  return "/" + "teams" + "/" + "members" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /teams/invites(.:format) */
export function teamsInvitesPath(options?: object): string {
  return "/" + "teams" + "/" + "invites" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /teams/invites/:id(.:format) */
export function teamsInvitePath(id: ScalarType, options?: object): string {
  return "/" + "teams" + "/" + "invites" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /teams(.:format) */
export function teamsPath(options?: object): string {
  return "/" + "teams" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /academies/:id/edit(.:format) */
export function editAcademyPath(id: ScalarType, options?: object): string {
  return "/" + "academies" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /academies/:academy_id/search(.:format) */
export function academySearchPath(academy_id: ScalarType, options?: object): string {
  return "/" + "academies" + "/" + academy_id + "/" + "search" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["academy_id","format"]);
}

/** /academies/:id(.:format) */
export function academyPath(id: ScalarType, options?: object): string {
  return "/" + "academies" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /academies/courses/:id/edit(.:format) */
export function editAcademiesCoursePath(id: ScalarType, options?: object): string {
  return "/" + "academies" + "/" + "courses" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /academies/courses/:id(.:format) */
export function academiesCoursePath(id: ScalarType, options?: object): string {
  return "/" + "academies" + "/" + "courses" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /academies/course_lessons/new(.:format) */
export function newAcademiesCourseLessonPath(options?: object): string {
  return "/" + "academies" + "/" + "course_lessons" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /academies/course_lessons/:id/edit(.:format) */
export function editAcademiesCourseLessonPath(id: ScalarType, options?: object): string {
  return "/" + "academies" + "/" + "course_lessons" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /academies/course_lessons/:id(.:format) */
export function academiesCourseLessonPath(id: ScalarType, options?: object): string {
  return "/" + "academies" + "/" + "course_lessons" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /academies/course_categories/:id/edit(.:format) */
export function editAcademiesCourseCategoryPath(id: ScalarType, options?: object): string {
  return "/" + "academies" + "/" + "course_categories" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /academies/course_categories/:id(.:format) */
export function academiesCourseCategoryPath(id: ScalarType, options?: object): string {
  return "/" + "academies" + "/" + "course_categories" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /summary/team(.:format) */
export function summaryTeamPath(options?: object): string {
  return "/" + "summary" + "/" + "team" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /summary/folders(.:format) */
export function summaryFoldersPath(options?: object): string {
  return "/" + "summary" + "/" + "folders" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /summary/folders/:id(.:format) */
export function summaryFolderPath(id: ScalarType, options?: object): string {
  return "/" + "summary" + "/" + "folders" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /summary/users(.:format) */
export function summaryUsersPath(options?: object): string {
  return "/" + "summary" + "/" + "users" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /summary/users/:id(.:format) */
export function summaryUserPath(id: ScalarType, options?: object): string {
  return "/" + "summary" + "/" + "users" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /exam_question_answers(.:format) */
export function examQuestionAnswersPath(options?: object): string {
  return "/" + "exam_question_answers" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /exam_csvs/academy_csv_download(.:format) */
export function academyCsvDownloadExamCsvsPath(options?: object): string {
  return "/" + "exam_csvs" + "/" + "academy_csv_download" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /exam_csvs/folder_csv_download(.:format) */
export function folderCsvDownloadExamCsvsPath(options?: object): string {
  return "/" + "exam_csvs" + "/" + "folder_csv_download" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /exam_csvs(.:format) */
export function examCsvsPath(options?: object): string {
  return "/" + "exam_csvs" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /word_pronounciations(.:format) */
export function wordPronounciationsPath(options?: object): string {
  return "/" + "word_pronounciations" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/azure/cognitive/issue_token(.:format) */
export function apiAzureCognitiveIssueTokenPath(options?: object): string {
  return "/" + "api" + "/" + "azure" + "/" + "cognitive" + "/" + "issue_token" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/stats/watch(.:format) */
export function apiStatsWatchPath(options?: object): string {
  return "/" + "api" + "/" + "stats" + "/" + "watch" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/design/:id/add_creatomate_job(.:format) */
export function addCreatomateJobApiDesignPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "design" + "/" + id + "/" + "add_creatomate_job" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/design/:design_id/scenes(.:format) */
export function apiDesignScenesPath(design_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "design" + "/" + design_id + "/" + "scenes" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["design_id","format"]);
}

/** /api/scenes/:id/presign_image(.:format) */
export function presignImageApiScenePath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "scenes" + "/" + id + "/" + "presign_image" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/scenes/:id/uploaded_image(.:format) */
export function uploadedImageApiScenePath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "scenes" + "/" + id + "/" + "uploaded_image" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/scenes/:id/presign_audio(.:format) */
export function presignAudioApiScenePath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "scenes" + "/" + id + "/" + "presign_audio" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/scenes/:id/uploaded_audio(.:format) */
export function uploadedAudioApiScenePath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "scenes" + "/" + id + "/" + "uploaded_audio" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/scenes/:id(.:format) */
export function apiScenePath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "scenes" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/video/prepare(.:format) */
export function prepareApiVideoIndexPath(options?: object): string {
  return "/" + "api" + "/" + "video" + "/" + "prepare" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/video/uploaded(.:format) */
export function uploadedApiVideoIndexPath(options?: object): string {
  return "/" + "api" + "/" + "video" + "/" + "uploaded" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/video/:id/move(.:format) */
export function moveApiVideoPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "video" + "/" + id + "/" + "move" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/video/:id/duplicate(.:format) */
export function duplicateApiVideoPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "video" + "/" + id + "/" + "duplicate" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/video/:id/download(.:format) */
export function downloadApiVideoPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "video" + "/" + id + "/" + "download" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/video/:id/thumbnail/presign(.:format) */
export function presignApiThumbnailPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "video" + "/" + id + "/" + "thumbnail" + "/" + "presign" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/video/:id/thumbnail(.:format) */
export function apiThumbnailPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "video" + "/" + id + "/" + "thumbnail" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/video/:video_id/watch_link(.:format) */
export function apiVideoWatchLinkPath(video_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "video" + "/" + video_id + "/" + "watch_link" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["video_id","format"]);
}

/** /api/video/:video_id/watch_link/condition/response(.:format) */
export function apiVideoWatchLinkConditionResponseIndexPath(video_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "video" + "/" + video_id + "/" + "watch_link" + "/" + "condition" + "/" + "response" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["video_id","format"]);
}

/** /api/video/:video_id/subtitles/add_generate_job(.:format) */
export function addGenerateJobApiVideoSubtitlesPath(video_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "video" + "/" + video_id + "/" + "subtitles" + "/" + "add_generate_job" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["video_id","format"]);
}

/** /api/video/:video_id/subtitles/published(.:format) */
export function publishedApiVideoSubtitlesPath(video_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "video" + "/" + video_id + "/" + "subtitles" + "/" + "published" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["video_id","format"]);
}

/** /api/video/:video_id/subtitles/default_displayed(.:format) */
export function defaultDisplayedApiVideoSubtitlesPath(video_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "video" + "/" + video_id + "/" + "subtitles" + "/" + "default_displayed" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["video_id","format"]);
}

/** /api/video/:video_id/subtitles(.:format) */
export function apiVideoSubtitlesPath(video_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "video" + "/" + video_id + "/" + "subtitles" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["video_id","format"]);
}

/** /api/video/:video_id/narrations/presign(.:format) */
export function presignApiVideoNarrationsPath(video_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "video" + "/" + video_id + "/" + "narrations" + "/" + "presign" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["video_id","format"]);
}

/** /api/video/:video_id/narrations(.:format) */
export function apiVideoNarrationsPath(video_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "video" + "/" + video_id + "/" + "narrations" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["video_id","format"]);
}

/** /api/video/:video_id/thumbnail_sprites(.:format) */
export function apiVideoThumbnailSpritesPath(video_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "video" + "/" + video_id + "/" + "thumbnail_sprites" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["video_id","format"]);
}

/** /api/video/:video_id/timeline_update_jobs(.:format) */
export function apiVideoTimelineUpdateJobsPath(video_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "video" + "/" + video_id + "/" + "timeline_update_jobs" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["video_id","format"]);
}

/** /api/video/:video_id/exam/questions(.:format) */
export function apiVideoExamQuestionsPath(video_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "video" + "/" + video_id + "/" + "exam" + "/" + "questions" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["video_id","format"]);
}

/** /api/video/:video_id/exam/answers(.:format) */
export function apiVideoExamAnswersPath(video_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "video" + "/" + video_id + "/" + "exam" + "/" + "answers" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["video_id","format"]);
}

/** /api/video/:video_id/exam(.:format) */
export function apiVideoExamPath(video_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "video" + "/" + video_id + "/" + "exam" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["video_id","format"]);
}

/** /api/video/:id(.:format) */
export function apiVideoPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "video" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/folders/:id/move(.:format) */
export function moveApiFolderPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "folders" + "/" + id + "/" + "move" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/folders/:id/rename(.:format) */
export function renameApiFolderPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "folders" + "/" + id + "/" + "rename" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/folders/:id/videos(.:format) */
export function videosApiFolderPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "folders" + "/" + id + "/" + "videos" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/folders(.:format) */
export function apiFoldersPath(options?: object): string {
  return "/" + "api" + "/" + "folders" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/folders/:id(.:format) */
export function apiFolderPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "folders" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/academy/:academy_id/watch_link(.:format) */
export function apiAcademyWatchLinkPath(academy_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "academy" + "/" + academy_id + "/" + "watch_link" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["academy_id","format"]);
}

/** /api/word_pronounciations/affect_design(.:format) */
export function affectDesignApiWordPronounciationsPath(options?: object): string {
  return "/" + "api" + "/" + "word_pronounciations" + "/" + "affect_design" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/word_pronounciations(.:format) */
export function apiWordPronounciationsPath(options?: object): string {
  return "/" + "api" + "/" + "word_pronounciations" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/word_pronounciations/:id(.:format) */
export function apiWordPronounciationPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "word_pronounciations" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/academy/logo/presign(.:format) */
export function presignApiAcademyLogoPath(options?: object): string {
  return "/" + "api" + "/" + "academy" + "/" + "logo" + "/" + "presign" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/academy/logo(.:format) */
export function apiAcademyLogoPath(options?: object): string {
  return "/" + "api" + "/" + "academy" + "/" + "logo" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/academy/courses(.:format) */
export function apiAcademyCoursesPath(options?: object): string {
  return "/" + "api" + "/" + "academy" + "/" + "courses" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/academy/courses/:id(.:format) */
export function apiAcademyCoursePath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "academy" + "/" + "courses" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/academy/course_lessons(.:format) */
export function apiAcademyCourseLessonsPath(options?: object): string {
  return "/" + "api" + "/" + "academy" + "/" + "course_lessons" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/academy/course_lessons/:id(.:format) */
export function apiAcademyCourseLessonPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "academy" + "/" + "course_lessons" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/academy/course_categories(.:format) */
export function apiAcademyCourseCategoriesPath(options?: object): string {
  return "/" + "api" + "/" + "academy" + "/" + "course_categories" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/academy/course_categories/:id(.:format) */
export function apiAcademyCourseCategoryPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "academy" + "/" + "course_categories" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/summary/watch_per_periods/condition(.:format) */
export function conditionApiSummaryWatchPerPeriodsPath(options?: object): string {
  return "/" + "api" + "/" + "summary" + "/" + "watch_per_periods" + "/" + "condition" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/summary/watch_per_periods(.:format) */
export function apiSummaryWatchPerPeriodsPath(options?: object): string {
  return "/" + "api" + "/" + "summary" + "/" + "watch_per_periods" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/summary/watch_per_videos(.:format) */
export function apiSummaryWatchPerVideosPath(options?: object): string {
  return "/" + "api" + "/" + "summary" + "/" + "watch_per_videos" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}
