import { LoadingContextProvider } from "../../common/loading/LoadingContext";
import { VideoContextProvider } from "../VideoContext";
import { WordPronounciationsContextProvider } from "../WordPronounciationsContext";
import { DesignSceneEditProps, DesignScenesEdit } from "./DesignScenesEdit";

function DesignScenesEditContainer(props: DesignSceneEditProps) {
  return (
    <LoadingContextProvider>
      <VideoContextProvider videoId={props.videoId}>
        <WordPronounciationsContextProvider>
          <DesignScenesEdit {...props} />
        </WordPronounciationsContextProvider>
      </VideoContextProvider>
    </LoadingContextProvider>
  );
}
export default DesignScenesEditContainer;
