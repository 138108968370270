import { BlockBlobClient } from "@azure/storage-blob";
import { VideoState } from "app/javascript/types/video";
import {
  Configuration,
  ApiVideoApi,
  ApiVideoIdPutRequest,
  ApiSubtitleApi,
  ApiWatchLinkApi,
  ApiSceneApi,
  ApiDesignApi,
  ApiDesignDesignIdScenesPostRequest,
  ApiScenesIdPutRequest,
  ApiWordPronounciationApi,
} from "../../generated/api";

export async function createDesignScene(
  csrfToken: string,
  designId: number,
  param: Required<ApiDesignDesignIdScenesPostRequest>
) {
  const api = new ApiSceneApi(
    new Configuration({
      basePath: "",
      headers: {
        "x-hopper-api-version": "1.0",
        "X-CSRF-Token": csrfToken,
      },
    })
  );
  return api.apiDesignDesignIdScenesPost({
    designId,
    apiDesignDesignIdScenesPostRequest: param,
  });
}

export async function updateDesignScene(
  csrfToken: string,
  sceneId: number,
  param: ApiScenesIdPutRequest
) {
  const api = new ApiSceneApi(
    new Configuration({
      basePath: "",
      headers: {
        "x-hopper-api-version": "1.0",
        "X-CSRF-Token": csrfToken,
      },
    })
  );
  return api.apiScenesIdPut({
    id: sceneId,
    apiScenesIdPutRequest: param,
  });
}

export async function deleteDesignScene(csrfToken: string, sceneId: number) {
  const api = new ApiSceneApi(
    new Configuration({
      basePath: "",
      headers: {
        "x-hopper-api-version": "1.0",
        "X-CSRF-Token": csrfToken,
      },
    })
  );
  return api.apiScenesIdDelete({ id: sceneId });
}

export async function uploadDesignSceneImage(
  csrfToken: string,
  sceneId: number,
  file: File
) {
  const api = new ApiSceneApi(
    new Configuration({
      basePath: "",
      headers: {
        "x-hopper-api-version": "1.0",
        "X-CSRF-Token": csrfToken,
      },
    })
  );
  const presign = await api.apiScenesIdPresignImageGet({ id: sceneId });

  const blobClient = new BlockBlobClient(presign.endpoint);
  await blobClient.uploadData(file, {
    blobHTTPHeaders: { blobContentType: file.type },
  });

  const url = new URL(presign.endpoint);
  const [, , blobName] = url.pathname.split("/");

  return api.apiScenesIdUploadedImagePost({
    id: sceneId,
    apiScenesIdUploadedImagePostRequest: { imageBlobName: blobName },
  });
}

export async function uploadDesignSceneAudio(
  csrfToken: string,
  sceneId: number,
  file: File,
  opt?: { abortSignal: AbortSignal }
) {
  const api = new ApiSceneApi(
    new Configuration({
      basePath: "",
      headers: {
        "x-hopper-api-version": "1.0",
        "X-CSRF-Token": csrfToken,
      },
    })
  );
  const presign = await api.apiScenesIdPresignAudioGet(
    { id: sceneId },
    { signal: opt?.abortSignal }
  );

  const blobClient = new BlockBlobClient(presign.endpoint);
  await blobClient.uploadData(file, {
    blobHTTPHeaders: { blobContentType: file.type },
    abortSignal: opt?.abortSignal,
  });

  const url = new URL(presign.endpoint);
  const [, , blobName] = url.pathname.split("/");

  return api.apiScenesIdUploadedAudioPost(
    {
      id: sceneId,
      apiScenesIdUploadedAudioPostRequest: { audioBlobName: blobName },
    },
    { signal: opt?.abortSignal }
  );
}

export async function getDesignScenes(designId: number) {
  const api = new ApiSceneApi(
    new Configuration({
      basePath: "",
      headers: {
        "x-hopper-api-version": "1.0",
      },
    })
  );
  return api.apiDesignDesignIdScenesGet({ designId });
}

export async function addCreatomateJob(
  csrfToken: string,
  designId: number,
  param: Parameters<
    ApiDesignApi["apiDesignIdAddCreatomateJobPost"]
  >[0]["apiDesignIdAddCreatomateJobPostRequest"]["creatomateParam"]
) {
  const api = new ApiDesignApi(
    new Configuration({
      basePath: "",
      headers: {
        "x-hopper-api-version": "1.0",
        "X-CSRF-Token": csrfToken,
      },
    })
  );
  return api.apiDesignIdAddCreatomateJobPost({
    id: designId,
    apiDesignIdAddCreatomateJobPostRequest: {
      creatomateParam: param,
    },
  });
}

export function publishVideoSubtitles(
  csrfToken: string,
  videoId: number,
  subtitles: Required<
    Parameters<
      ApiSubtitleApi["apiVideoVideoIdSubtitlesPut"]
    >[0]["apiVideoVideoIdSubtitlesPutRequest"]["subtitles"][number]
  >[]
) {
  const api = new ApiSubtitleApi(
    new Configuration({
      basePath: "",
      headers: {
        "x-hopper-api-version": "1.0",
        "X-CSRF-Token": csrfToken,
      },
    })
  );
  return Promise.all([
    api.apiVideoVideoIdSubtitlesPublishedPatch({
      videoId,
      apiVideoVideoIdSubtitlesPublishedPatchRequest: { status: true },
    }),
    api.apiVideoVideoIdSubtitlesPut({
      videoId,
      apiVideoVideoIdSubtitlesPutRequest: { subtitles },
    }),
  ]);
}

export function updateVideoSubtitlesDefaultDisplayed(
  csrfToken: string,
  videoId: number,
  defaultDisplayed: boolean
) {
  const api = new ApiSubtitleApi(
    new Configuration({
      basePath: "",
      headers: {
        "x-hopper-api-version": "1.0",
        "X-CSRF-Token": csrfToken,
      },
    })
  );
  return api.apiVideoVideoIdSubtitlesDefaultDisplayedPatch({
    videoId,
    apiVideoVideoIdSubtitlesDefaultDisplayedPatchRequest: {
      defaultDisplayed,
    },
  });
}

export function getVideoWatchLink(videoId: number) {
  const api = new ApiWatchLinkApi(
    new Configuration({
      basePath: "",
      headers: {
        "x-hopper-api-version": "1.0",
      },
    })
  );
  return api.apiVideoVideoIdWatchLinkGet({ videoId });
}

export async function getAllWordPronounciations() {
  const api = new ApiWordPronounciationApi(
    new Configuration({
      basePath: "",
      headers: { "x-hopper-api-version": "1.0" },
    })
  );
  let page = 1;
  let pageResponse: Awaited<ReturnType<typeof api.apiWordPronounciationsGet>> =
    [];
  const result = [];
  do {
    pageResponse = await api.apiWordPronounciationsGet({ page });
    if (pageResponse.length > 0) {
      result.push(...pageResponse);
    }
    page++;
  } while (pageResponse.length > 0);
  return result;
}

type Video = Omit<
  Awaited<ReturnType<ApiVideoApi["apiVideoIdGet"]>>,
  "state"
> & {
  state: VideoState;
};

export function getVideo(videoId: number) {
  const api = new ApiVideoApi(new Configuration({ basePath: "" }));
  return api.apiVideoIdGet({ id: videoId }) as Promise<Video>;
}

export function updateVideo(
  csrfToken: string,
  videoId: number,
  video: ApiVideoIdPutRequest
) {
  const api = new ApiVideoApi(
    new Configuration({
      basePath: "",
      headers: {
        "x-hopper-api-version": "1.0",
        "X-CSRF-Token": csrfToken,
      },
    })
  );
  return api.apiVideoIdPut({
    id: videoId,
    apiVideoIdPutRequest: video,
  }) as Promise<Video>;
}

export function getAffectDesignScenes(csrfToken: string, designId: number) {
  const api = new ApiWordPronounciationApi(
    new Configuration({
      basePath: "",
      headers: {
        "x-hopper-api-version": "1.0",
        "X-CSRF-Token": csrfToken,
      },
    })
  );
  return api.apiWordPronounciationsAffectDesignGet({
    designId,
  });
}
