import { useEffect, useRef, useState } from "react";
import { VideoState } from "../../../types/video";
import { usePlayerWatchLog } from "./usePlayerWatchLog";
import {
  queryTimestampAnchorElements,
  TimestampLinkParser,
} from "../../../lib/timestamp-link";
import { videojs } from "../../../lib/videojs";
import loadingImg from "images/loading-white.svg";
import type ErrorDisplay from "video.js/dist/types/error-display";

interface Props {
  location?: { href: string; origin: string; pathname: string };
  state: VideoState;
  sourceUrl: string;
  posterUrl?: string;
  contentType: string;
  subtitles?: {
    url: string;
    lang: string;
    label: string;
    default: boolean;
  }[];
  videoId: string; // video の hashid
  videoUUID: string;
  apiBaseUrl?: string;
  apiV2BaseUrl?: string;
  enableWatchLog?: boolean;
  enableTimestampLinkHook?: boolean;
  teamId?: number;
  tenantId?: number;
  ipAddress?: string;
}

function VideoPlayerContainer(props: Props) {
  const readyState = useWaitUrlReady(props.sourceUrl, props.contentType);

  if (!props.sourceUrl) {
    return <p>再生する準備が出来ていません。</p>;
  }

  // 不要な描画による画面のチラつきを避けるため、あくまでリトライが行われているときだけ読み込み中の表示をする
  if (readyState === "init") {
    return <></>;
  }
  if (readyState === "retrying") {
    return (
      <div className="c-video-player__retrying">
        <img src={loadingImg} alt="" width={48} height={48} />
      </div>
    );
  }
  // if (readyState === "ready")
  return <VideoPlayer {...props} />;
}
export default VideoPlayerContainer;

export function VideoPlayer({ location = window.location, ...props }: Props) {
  const videoRef = useRef<HTMLVideoElement>();

  const { addWatchLog } = usePlayerWatchLog(
    props.apiBaseUrl,
    props.videoId,
    props.teamId,
    props.tenantId,
    props.ipAddress
  );

  const { addWatchLog: addV2WatchLog } = usePlayerWatchLog(
    props.apiV2BaseUrl,
    props.videoUUID,
    props.teamId,
    props.tenantId,
    props.ipAddress
  );

  useEffect(() => {
    // https://videojs.com/guides/options/
    const options = {
      fill: true, // https://videojs.com/guides/layout/#fill-mode
      playbackRates: [2.0, 1.75, 1.5, 1.25, 1.0, 0.75, 0.5],
      controlBar: {
        remainingTimeDisplay: false,
      },
      // source要素を使うと、モバイル端末のネットワーク環境次第では正常に読み込めない場合がある。
      // video.jsのパラメータとして明示的に指定し、端末やブラウザの挙動の問題を回避する。
      sources: [{ src: props.sourceUrl, type: props.contentType }],
    };
    const player = videojs(videoRef.current, options);
    player.on("error", () => {
      const err = player.error();
      Rollbar.warn(err.message, err);

      // MKIOの再生準備が遅いための読み込みエラーの際、エラーメッセージをユーザーに次の操作を促す内容に変更。
      if (
        MediaError.MEDIA_ERR_NETWORK === err.code ||
        MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED === err.code
      ) {
        const errorDisplay = player.getChild("ErrorDisplay") as ErrorDisplay;
        errorDisplay?.fillWith(
          "再生の準備に時間がかかっています。再読み込みしてください。"
        );
        errorDisplay?.el()?.append(buildReloadButton());
      }
    });
    player.reloadSourceOnError();
    player.hlsQualitySelector();
    player.ready(() => {
      // https://stackoverflow.com/a/49598388
      const textTrackSettings = player.textTrackSettings;
      textTrackSettings.setValues({ backgroundOpacity: "0.5" });
      textTrackSettings.updateDisplay();
    });
    player.on("usage", (event: { name: string } & Event) => {
      if (event.name === "vhs-error-reload") {
        const name = event.name;
        const lastError = player?.error();
        Rollbar.debug("reloadSourceOnError", { name, lastError });
      }
    });

    if (props.enableWatchLog) {
      const [playerName, playerVersion] = Object.entries(player.version())[0];
      player.on("timeupdate", () => {
        addWatchLog({
          playerName,
          playerVersion,
          sec: player.currentTime(),
          paused: player.paused(),
          mute: player.muted(),
          volume: player.volume(),
        });

        if (props.apiV2BaseUrl != "") {
          addV2WatchLog({
            playerName,
            playerVersion,
            sec: player.currentTime(),
            paused: player.paused(),
            mute: player.muted(),
            volume: player.volume(),
          });
        }
      });
    }

    if (props.enableTimestampLinkHook) {
      const initialTime = new TimestampLinkParser(
        location.href,
        location
      ).time();
      if (initialTime) {
        player.on("loadeddata", () => {
          if (initialTime <= player.duration()) {
            player.poster(null);
            player.one("seeked", () =>
              player.play().catch((e) => {
                // ユーザー操作に起因しない自動再生の可否はブラウザ側のポリシーで決定され、制御できないのでエラーを許容する。
                console.warn(e);
              })
            );
            player.currentTime(initialTime);
          }
        });
      }

      queryTimestampAnchorElements().forEach(({ elem, time }) => {
        elem.addEventListener("click", (event) => {
          if (event.ctrlKey || event.metaKey) {
            return; // Ctrl/⌘+クリックを除外
          }
          event.preventDefault();
          if (time <= player.duration()) {
            player.one("seeked", () => player.play());
            player.currentTime(time);
            window.scroll({ top: 0 });
          }
        });
      });
    }

    return () => {
      player.dispose();
    };
  }, [
    location,
    addWatchLog,
    props.enableWatchLog,
    addV2WatchLog,
    props.apiV2BaseUrl,
    props.enableTimestampLinkHook,
    props.sourceUrl,
    props.contentType,
  ]);

  return (
    <video
      className="video-js"
      controls
      ref={videoRef}
      poster={props.posterUrl || null}
      preload="auto" // webmの場合AMPがpreload=noneにしてしまうので設定を明示
    >
      {props.subtitles?.map((track) => (
        <track
          key={track.lang}
          src={track.url}
          srcLang={track.lang}
          label={track.label}
          kind="subtitles"
          default={track.default}
        />
      ))}
    </video>
  );
}

type ReadyState = "init" | "retrying" | "ready";
function useWaitUrlReady(url: string, type: string) {
  // ストリーミング配信時のみURL有効性をチェックしたいので、ビデオファイルを直接プレビュー再生する際は最初からreadyとする
  const [state, setState] = useState<ReadyState>(
    type.startsWith("video/") ? "ready" : "init"
  );
  const initialReqRef = useRef(false);

  // 最速で処理させるためuseEffectは使わない。
  if (url && !initialReqRef.current) {
    initialReqRef.current = true;
    if (state !== "ready") {
      // 仮に30秒程度はウェイトする
      pollWaitUrlReady(url, 30, setState);
    }
  }
  return state;
}
async function pollWaitUrlReady(
  url: string,
  retryNum: number,
  setState: (state: ReadyState) => void
) {
  if (retryNum <= 0) {
    setState("ready"); // タイムアウト時はチェックを諦めてプレイヤーを表示
    return;
  }

  const res = await fetch(url).catch((err) => {
    // ネットワークエラー時はthrowせずリトライさせる
    console.warn(err);
    return { ok: false };
  });
  if (res.ok) {
    setState("ready");
  } else {
    setState("retrying");
    setTimeout(() => pollWaitUrlReady(url, retryNum - 1, setState), 1000);
  }
}

// エラー時のための再読み込みボタンの簡易実装
function buildReloadButton() {
  const btn = document.createElement("button");
  btn.addEventListener("click", () => location.reload());
  btn.textContent = "再読み込み";
  btn.classList.add("vjs-location-reload-button");
  return btn;
}
