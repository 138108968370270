/* tslint:disable */
/* eslint-disable */
/**
 * hopper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiScenesIdPutRequestAudioData } from './ApiScenesIdPutRequestAudioData';
import {
    ApiScenesIdPutRequestAudioDataFromJSON,
    ApiScenesIdPutRequestAudioDataFromJSONTyped,
    ApiScenesIdPutRequestAudioDataToJSON,
} from './ApiScenesIdPutRequestAudioData';

/**
 * 
 * @export
 * @interface ApiWordPronounciationsAffectDesignGet200ResponseDataInner
 */
export interface ApiWordPronounciationsAffectDesignGet200ResponseDataInner {
    /**
     * 
     * @type {number}
     * @memberof ApiWordPronounciationsAffectDesignGet200ResponseDataInner
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiWordPronounciationsAffectDesignGet200ResponseDataInner
     */
    designId?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiWordPronounciationsAffectDesignGet200ResponseDataInner
     */
    textHtml?: string;
    /**
     * 
     * @type {ApiScenesIdPutRequestAudioData}
     * @memberof ApiWordPronounciationsAffectDesignGet200ResponseDataInner
     */
    audioData?: ApiScenesIdPutRequestAudioData;
    /**
     * 
     * @type {any}
     * @memberof ApiWordPronounciationsAffectDesignGet200ResponseDataInner
     */
    imageBlobName?: any | null;
    /**
     * 
     * @type {number}
     * @memberof ApiWordPronounciationsAffectDesignGet200ResponseDataInner
     */
    rowOrder?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiWordPronounciationsAffectDesignGet200ResponseDataInner
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiWordPronounciationsAffectDesignGet200ResponseDataInner
     */
    updatedAt?: string;
}

/**
 * Check if a given object implements the ApiWordPronounciationsAffectDesignGet200ResponseDataInner interface.
 */
export function instanceOfApiWordPronounciationsAffectDesignGet200ResponseDataInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiWordPronounciationsAffectDesignGet200ResponseDataInnerFromJSON(json: any): ApiWordPronounciationsAffectDesignGet200ResponseDataInner {
    return ApiWordPronounciationsAffectDesignGet200ResponseDataInnerFromJSONTyped(json, false);
}

export function ApiWordPronounciationsAffectDesignGet200ResponseDataInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiWordPronounciationsAffectDesignGet200ResponseDataInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'designId': !exists(json, 'design_id') ? undefined : json['design_id'],
        'textHtml': !exists(json, 'text_html') ? undefined : json['text_html'],
        'audioData': !exists(json, 'audio_data') ? undefined : ApiScenesIdPutRequestAudioDataFromJSON(json['audio_data']),
        'imageBlobName': !exists(json, 'image_blob_name') ? undefined : json['image_blob_name'],
        'rowOrder': !exists(json, 'row_order') ? undefined : json['row_order'],
        'createdAt': !exists(json, 'created_at') ? undefined : json['created_at'],
        'updatedAt': !exists(json, 'updated_at') ? undefined : json['updated_at'],
    };
}

export function ApiWordPronounciationsAffectDesignGet200ResponseDataInnerToJSON(value?: ApiWordPronounciationsAffectDesignGet200ResponseDataInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'design_id': value.designId,
        'text_html': value.textHtml,
        'audio_data': ApiScenesIdPutRequestAudioDataToJSON(value.audioData),
        'image_blob_name': value.imageBlobName,
        'row_order': value.rowOrder,
        'created_at': value.createdAt,
        'updated_at': value.updatedAt,
    };
}

