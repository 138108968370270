import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import TextareaAutosize from "react-textarea-autosize";
import { formatTimeMs } from "../../../lib/formatTimeMs";
import { useState, CSSProperties, ChangeEvent, useContext } from "react";
import { useSubMenuVisible } from "../../common/submenus/useSubMenuVisible";
import {
  deleteDesignScene,
  updateDesignScene,
  uploadDesignSceneImage,
} from "../requests";
import { DesignScene } from "../helpers";
import { LoadingContext } from "../../common/loading/LoadingContext";

interface DesignSceneItemProps {
  csrfToken: string;
  index: number;
  scene: DesignScene;
  /** 自シーンと1つ前のシーンがともに選択されているかどうか */
  beforeScene: boolean;
  /** 自シーンと1つ後のシーンがともに選択されているかどうか */
  afterScene: boolean;
  durationMs: number;
  isChecked: boolean;
  isDragging: boolean;
  isActiveScene: boolean;
  isTextareaFocused: boolean;
  isCompleteUpdateNotify: boolean;
  onUpdateScene: (scene: DesignScene) => void;
  onDeleteScene: () => void;
  onFocus: () => void;
  onCheckboxSceneChange: (sceneId: number) => void;
  isUpdateWordPronounciationsLiteralScene?: boolean;
}

const acceptImageMimeTypes = ["image/jpeg", "image/png"];
function validateImageFile(file: File) {
  if (!file) {
    alert("ファイルが選択されていません。");
    return false;
  }
  if (file.size > 1024 * 1024) {
    alert("1MB以下のファイルを選択してください。");
    return false;
  }
  if (!acceptImageMimeTypes.includes(file.type)) {
    alert("JPEG, PNG, SVGファイルを選択してください。");
    return false;
  }
  return true;
}

export function DesignSceneItem(props: DesignSceneItemProps) {
  const { handleLoading } = useContext(LoadingContext);
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.scene.id.toString() });
  const [visible, toggleVisible] = useSubMenuVisible();
  const sceneId = props.scene.id;
  const scene = props.scene;
  const isChecked = props.isChecked;
  const isDragging = props.isDragging;
  const isActiveScene = props.isActiveScene;
  const isTextareaFocused = props.isTextareaFocused;
  const isCompleteUpdateNotify = props.isCompleteUpdateNotify;
  const beforeScene = props.beforeScene;
  const afterScene = props.afterScene;
  const isUpdateWordPronounciationsLiteralScene =
    props.isUpdateWordPronounciationsLiteralScene;
  async function updateText(textHtml: string) {
    if (scene.textHtml === textHtml) {
      props.onUpdateScene(scene);
      return;
    }
    const newScene = await handleLoading(
      updateDesignScene(props.csrfToken, sceneId, {
        textHtml,
      })
    );
    props.onUpdateScene(newScene);
  }

  async function deleteScene() {
    if (!confirm("シーンを削除します。よろしいですか？")) {
      return;
    }
    await handleLoading(deleteDesignScene(props.csrfToken, sceneId));
    props.onDeleteScene();
  }

  async function uploadImage(event: ChangeEvent<HTMLInputElement>) {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    if (!validateImageFile(file)) {
      return;
    }
    const newScene = await handleLoading(
      uploadDesignSceneImage(props.csrfToken, sceneId, file)
    );
    props.onUpdateScene(newScene);
  }

  const rowStyle: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging && isChecked ? 0.5 : 1,
    backgroundColor: isChecked ? "#f2f5fe" : "",
    display: isChecked && isDragging && !isActiveScene ? "none" : "",
    borderTop: beforeScene ? "1px solid transparent" : "",
    borderBottom: afterScene ? "1px solid transparent" : "",
  };

  return (
    <div
      className={`p-design-scene
    ${
      isChecked
        ? "p-design__show-on-hover-container--checked"
        : "p-design__show-on-hover-container"
    }
    ${isUpdateWordPronounciationsLiteralScene ? "has-update" : ""}`}
      ref={setNodeRef}
      style={rowStyle}
    >
      <div
        className="p-design-scene-indicator"
        style={{ pointerEvents: isTextareaFocused ? "none" : "auto" }}
      >
        <span className="p-design-scene-indicator__no">
          {`${props.index + 1}`.padStart(2, "0")}
        </span>
        <label
          className="p-design-scene-indicator__checkbox-wrapper"
          style={
            isChecked
              ? {
                  position: "relative",
                  display: "inline-block",
                  cursor: "pointer",
                }
              : {}
          }
        >
          <input
            className="p-design-scene-indicator__checkbox-input"
            type="checkbox"
            checked={isChecked}
            onChange={() => props.onCheckboxSceneChange(scene.id)}
          />
          <span className="p-design-scene-indicator__checkbox-label"></span>
        </label>
        <button
          className={`material-icons-round p-design-scene-indicator__drag-handle ${
            isChecked
              ? "p-design__show-on-checked-item"
              : "p-design__show-on-hover-item"
          }`}
          title="ドラッグして順番を変更"
          {...attributes}
          {...listeners}
          style={{
            cursor: transform ? "grabbing" : "grab",
          }}
        >
          drag_indicator
        </button>
      </div>
      <label
        className={`p-design-scene-thumb ${
          scene.imageUrl ? "" : "p-design-scene-thumb--none"
        }`}
      >
        <time className="p-design-scene-thumb__time">
          {formatTimeMs(props.durationMs)}
        </time>
        <input
          className="p-design-scene-thumb__input"
          type="file"
          accept={acceptImageMimeTypes.join(",")}
          onChange={uploadImage}
        />
        {scene.imageUrl && (
          <>
            <img
              className="p-design-scene-thumb__img-elem"
              src={scene.imageUrl}
            />
            <div className="p-design-scene-thumb__img-change-indicator">
              <i className="material-icons-round p-design-scene-thumb__img-change-indicator-icon">
                sync
              </i>
              変更
            </div>
          </>
        )}
        {!scene.imageUrl && (
          <>
            <i className="material-icons-round">library_add</i>
            ファイルを登録
          </>
        )}
      </label>
      <DesignSceneTextField
        className={`p-design-scene__textfield ${
          isUpdateWordPronounciationsLiteralScene ? "has-error" : ""
        }`}
        placeholder="スクリプトを入力"
        value={scene.textHtml ?? ""}
        onChange={(newTextHtml) => updateText(newTextHtml)}
        onFocus={props.onFocus}
      />
      <div className="c-submenu">
        <button
          className={`material-icons-round c-submenu__button c-submenu__button--minimal ${
            isChecked
              ? "p-design__show-on-checked-item"
              : "p-design__show-on-item"
          }`}
          onClick={() => toggleVisible()}
        >
          more_vert
        </button>
        {visible && (
          <ul
            role="menu"
            className="c-submenu__list c-submenu__list--align-left"
          >
            <li role="menuitem" className="c-submenu__list-item danger">
              <button onClick={deleteScene}>
                <i className="material-icons-outlined c-submenu__list-item-icon">
                  delete
                </i>
                削除
              </button>
            </li>
          </ul>
        )}
      </div>
      {isCompleteUpdateNotify && (
        <div className="p-design-scene-complete-update-notify">
          <span className="p-design-scene-complete-update-notify-icon material-icons-outlined">
            check
          </span>
          追加完了
        </div>
      )}
    </div>
  );
}

function DesignSceneTextField(props: {
  className?: string;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  onFocus?: () => void;
}) {
  const [value, setValue] = useState(props.value ?? "");

  // Reactがwrapしたイベントでなく、生のchangeイベント相当のタイミングで処理したいので、onBlurを使う。
  function onChange() {
    const newValue = sanitizeTextHtml(value);
    setValue(newValue);
    props.onChange(newValue);
  }

  return (
    <TextareaAutosize
      className={props.className}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={onChange}
      onFocus={props.onFocus}
    />
  );
}

function sanitizeTextHtml(textHtml: string) {
  // NOTE: 設計次第だが、発音設定のための実装時にcontentEditableのゴミが入るので、それを削除したりも必要になる見込み。
  return (textHtml ?? "").trim();
}
