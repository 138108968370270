import { createContext, ReactNode } from "react";
import { getAllWordPronounciations } from "./requests";

type WordPronounciationsContextType = ReturnType<
  typeof getAllWordPronounciations
>;

export const WordPronounciationsContext =
  createContext<WordPronounciationsContextType>(null);
WordPronounciationsContext.displayName = "WordPronounciationsContext";

export function WordPronounciationsContextProvider(props: {
  children: ReactNode;
}) {
  const wordPronounciationPromises = getAllWordPronounciations();
  return (
    <WordPronounciationsContext.Provider value={wordPronounciationPromises}>
      {props.children}
    </WordPronounciationsContext.Provider>
  );
}
