// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
Rails.start();

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

document.addEventListener("DOMContentLoaded", () => {
  const container = document.getElementById("container");
  if (container) {
    require("react-modal").setAppElement(container);
  }

  // ヘッダメニュー
  document.getElementById("headerUser")?.addEventListener("click", () => {
    const nav = document.getElementById("headerUserNav");
    if (nav.style.display) {
      nav.style.display = null;
      setTimeout(() => {
        document.body.addEventListener(
          "click",
          () => (nav.style.display = "none"),
          { once: true, passive: true }
        );
      }, 100);
    } else {
      nav.style.display = "none";
    }
  });
});

const componentRequireContext = require.context("components", true);
const ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
