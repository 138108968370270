import { VFC, MouseEventHandler, useState, CSSProperties } from "react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  useSortable,
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useSubMenuVisible } from "../common/submenus/useSubMenuVisible";
import { ApiAcademyCourseLessonApi, Configuration } from "../../generated/api";
import { notify } from "../notification";
import { DurationText } from "../common/DurationText";
import { stripTags } from "../../lib/stripTags";
import {
  editAcademiesCourseLessonPath,
  videoPath,
} from "../../generated/routes";

import type { VideoWatchLinkType } from "../../types/videoWatchLink";

type AcademyCourseLesson = Awaited<
  ReturnType<ApiAcademyCourseLessonApi["apiAcademyCourseLessonsIdGet"]>
> & { video: { watchLink: { typeName: VideoWatchLinkType } } };

interface Props {
  csrfToken: string;
  lessons: AcademyCourseLesson[];
}

export const AcademyCourseLessonListEdit: VFC<Props> = (props) => {
  const dndSensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const [lessons, setLessons] = useState(props.lessons ?? []);

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = lessons.findIndex((i) => i.id.toString() === active.id);
      const newIndex = lessons.findIndex((i) => i.id.toString() === over.id);
      const target = lessons[oldIndex];
      setLessons(arrayMove(lessons, oldIndex, newIndex));
      lessonApi(props.csrfToken)
        .apiAcademyCourseLessonsIdPatch({
          id: target.id,
          apiAcademyCourseLessonsIdPatchRequest: {
            courseLesson: { rowOrderPosition: newIndex },
          },
        })
        .catch((reason) => {
          notify(
            "エラーが発生しました。画面をリロードしてもう一度試してください。"
          );
          throw reason;
        });
    }
  }

  return (
    <DndContext
      sensors={dndSensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={lessons.map((c) => c.id.toString())}
        strategy={verticalListSortingStrategy}
      >
        <table className="p-academy-edit__table">
          <thead>
            <tr>
              <th>{/* drag handle */}</th>
              <th>{/* video thumbnail */}</th>
              <th>レッスン名・説明</th>
              <th>公開</th>
              <th>長さ</th>
              <th>{/* submenu */}</th>
            </tr>
          </thead>
          <tbody>
            {lessons.map((lesson) =>
              lesson.video ? (
                <ListItem
                  key={lesson.id}
                  csrfToken={props.csrfToken}
                  lessonId={lesson.id}
                  videoThumbnailUrl={lesson.video.thumbnailUrl}
                  title={lesson.video.title}
                  bodyHtml={lesson.video.descriptionHtml}
                  watchLinkType={lesson.video.watchLink.typeName}
                  playTimeSec={lesson.playTimeSec}
                  editMenuHref={videoPath(lesson.video.hashid)}
                />
              ) : (
                <ListItem
                  key={lesson.id}
                  csrfToken={props.csrfToken}
                  lessonId={lesson.id}
                  title={lesson.title}
                  bodyHtml={lesson.textHtml}
                  watchLinkType="no_limit"
                  playTimeSec={null}
                  editMenuHref={editAcademiesCourseLessonPath(lesson.hashid)}
                />
              )
            )}
            {lessons.length === 0 && (
              <tr style={{ background: "none" }}>
                <td colSpan={6}>
                  「レッスンを追加」ボタンでレッスンを追加してください。
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </SortableContext>
    </DndContext>
  );
};
export default AcademyCourseLessonListEdit;

interface ListItemProps {
  csrfToken: string;
  lessonId: number;
  videoThumbnailUrl?: string;
  title: string;
  bodyHtml: string;
  watchLinkType: VideoWatchLinkType;
  playTimeSec: number;
  editMenuHref: string;
}

const ListItem: VFC<ListItemProps> = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.lessonId.toString() });
  const [subMenuVisible, toggleSubMenuVisible] = useSubMenuVisible();

  const toggleMenu: MouseEventHandler = () => {
    toggleSubMenuVisible();
  };

  const onClickDel: MouseEventHandler = () => {
    if (!confirm(`レッスン「${props.title}」を削除しますか？`)) {
      return;
    }

    lessonApi(props.csrfToken)
      .apiAcademyCourseLessonsIdDelete({
        id: props.lessonId,
      })
      .then(() => {
        location.reload();
      })
      .catch((reason) => {
        notify(
          "エラーが発生しました。画面をリロードしてもう一度試してください。"
        );
        throw reason;
      });
  };

  const rowStyle: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  function watchLinkTypeName(typeName: VideoWatchLinkType) {
    const nameMap = {
      video_create_user_only: "非公開",
      member_only: "特定の人",
      no_limit: "一般公開",
    };
    return nameMap[typeName];
  }

  const bodyText = stripTags(props.bodyHtml);
  return (
    <tr ref={setNodeRef} style={rowStyle}>
      <td>
        <button
          className="p-academy-edit__table-drag-handle"
          {...attributes}
          {...listeners}
          style={{ cursor: transform ? "grabbing" : "grab" }}
        >
          <i className="material-icons-round">drag_handle</i>
        </button>
      </td>
      {props.videoThumbnailUrl && (
        <td className="p-academy-edit__table-thumbnail">
          <img src={props.videoThumbnailUrl} alt={props.title} />
        </td>
      )}
      <td
        className="p-academy-edit__table-info"
        title={props.title}
        colSpan={props.videoThumbnailUrl ? null : 2}
      >
        <p className="p-academy-edit__table-info-title" title={props.title}>
          {props.title}
        </p>
        <p className="p-academy-edit__table-info-desc" title={bodyText}>
          {bodyText}
        </p>
      </td>
      <td>{watchLinkTypeName(props.watchLinkType)}</td>
      <td>
        {props.playTimeSec ? (
          <DurationText
            value={props.playTimeSec}
            format={{ hours: true, minutes: true }}
          />
        ) : (
          "-"
        )}
      </td>
      <td>
        <button
          className="material-icons-round c-submenu__button"
          onClick={toggleMenu}
        >
          more_vert
        </button>
        {subMenuVisible && (
          <ul className="c-submenu__list p-academy-edit__table-submenu">
            <li className="c-submenu__list-item">
              <a href={props.editMenuHref}>
                <i className="material-icons-round c-submenu__list-item-icon">
                  edit
                </i>
                編集
              </a>
            </li>
            <li className="c-submenu__list-item danger">
              <button onClick={onClickDel}>
                <i className="material-icons-outlined c-submenu__list-item-icon">
                  delete
                </i>
                削除
              </button>
            </li>
          </ul>
        )}
      </td>
    </tr>
  );
};

function lessonApi(csrfToken: string) {
  return new ApiAcademyCourseLessonApi(
    new Configuration({
      basePath: "",
      headers: {
        "x-hopper-api-version": "1.0",
        "X-CSRF-Token": csrfToken,
      },
    })
  );
}
